export const navLinksdata = [
  {
    _id: 1001,
    title: "Sobre Mim",
    link: "home",
  },
  {
    _id: 1002,
    title: "Skills",
    link: "features",
  },
  {
    _id: 1003,
    title: "Projetos",
    link: "projects",
  },
  {
    _id: 1004,
    title: "Currículo",
    link: "resume",
  },
  {
    _id: 1005,
    title: "Contato",
    link: "contact",
  },
];